<template>
  <Dialog>
    <Form
      id="product-subscription-delivery-now-form"
      submit-label="formFields.form.confirm"
      cancel-label="formFields.form.cancel"
      @submit="onSubmit(data.subscription.id, quantity)"
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
    >
      <template #default>
        <div class="font-bold md:text-lg text-md font-text mb-sm">
          {{ t('shop.productSubscription.deliveryNow.subHeadline') }}
        </div>
        <div class="text-sm mb-sm md:text-md">
          {{ t('shop.productSubscription.deliveryNow.text') }}
        </div>
        <ContentSeparator class="relative mt-lg separator-overwrite" />
        <div class="product-grid">
          <div class="flex product-grid__image">
            <div class="relative w-[65px] sm:w-[150px] h-auto shrink-0">
              <ProductImage
                :image-url="data.product?.media?.cover"
                :alt-text="data.product?.media?.alt"
                class="w-[65px] h-[65px] sm:h-[120px] sm:w-[120px] object-scale-down mr-auto"
              />
            </div>
          </div>
          <div class="product-grid__productInfo">
            <div class="font-bold text-md md:text-lg font-deco-alt">
              {{ data.product.name }}
            </div>
            <div class="text-sm md:text-md">
              <span v-if="data.product.variantName">
                {{ data.product.variantName }}
                <span v-if="data.product.manufacturer"> | </span>
              </span>
              <span v-if="data.product.manufacturer">
                {{ data.product.manufacturer.name }}
              </span>
            </div>
            <div class="text-sm md:text-md">
              <span
                >{{ t('shop.cart.productFinder.itemNumber') }}
                {{ data.product.productNumber }}</span
              >
            </div>
          </div>
          <div class="text-sm product-grid__price md:text-md">
            <div class="font-deco-alt">
              <div
                class="text-lg font-bold leading-none md:text-2xl sm:leading-sm"
              >
                {{
                  n(
                    data.subscription.unitPrice * data.subscription.quantity,
                    'currency',
                  )
                }}
              </div>
              <div class="flex flex-row text-sm md:text-md">
                <VatShippingHint
                  keypath="shop.productList.excludingVAT"
                  tag="p"
                  classes="w-full text-right font-roboto md:w-auto md:hidden md:mb-md"
                />
                <VatShippingHint
                  keypath="shop.productList.excludingVAT"
                  tag="p"
                  classes="hidden w-full ml-auto text-right font-roboto md:w-auto md:block"
                />
              </div>
            </div>
          </div>
          <div class="product-grid__deliveryTime">
            <DeliveryTime
              class="!py-0 !my-0 text-sm md:text-md"
              :stock="data.product.stock"
              :availability-state="data.product.availabilityState"
            />
          </div>
          <div class="product-grid__quantity">
            <div class="md:w-[200px] flex flex-col flex-nowrap">
              <QuantityPicker
                :key="quantity"
                v-model="quantity"
                class="max-w-[100px] sm:max-w-[150px] w-full"
                :lower-limit="data.product.productSubscription.lowerLimit"
                :step-size="data.product.productSubscription.increaseQuantity"
                :debounce-emit="500"
                @click.prevent
                @update:model-value="(nv) => (quantity = nv)"
              />
            </div>
          </div>
          <div class="product-grid__infoBanner">
            <div class="flex h-full text-sm px-sm md:text-md">
              <div class="flex items-center icon-box bg-status-warn-light">
                <FaIcon
                  icon-class="fal fa-circle-exclamation"
                  classes="text-black mx-xs !w-sm !h-sm md:!h-[20px] md:!w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                {{ t('shop.productSubscription.deliveryNow.infoText') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Form>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import type { Product } from '~/server/transformers/shop/product/types';
import { DeliveryTime, QuantityPicker, ProductImage } from '@/complib';
import ContentSeparator from '@/components/block/seperator/contentSeparator.vue';
import FaIcon from '@/components/fa-icon.vue';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import VatShippingHint from '@/components/shop/atoms/vatShippingHint.vue';
import { handleLoadingError } from '~/utils/handleLoadingError';

const { t, n } = useTrans();
const dialogStore = useDialogStore();
dialogStore.setHeadline(t('shop.productSubscription.deliveryNow.title'));
const data = dialogStore.getDialogData<{
  subscription: ProductSubscriptionItem;
  product: Product;
}>();
const quantity = ref(data.subscription.quantity);

async function onSubmit(subscriptionId: string, quantity: number) {
  try {
    const site = useSiteIdent();
    const response = await useSecureSessionPost<string>(
      `/api/${site}/shop/product/productSubscription/deliverNow/${subscriptionId}`,
      {
        quantity: quantity,
      },
    );
    if (response) {
      dialogStore.closeDialog(DialogResponses.SUCCESS);
    } else {
      dialogStore.closeDialog(DialogResponses.CANCEL);
    }
  } catch (e) {
    handleLoadingError(e);
  }
}
</script>
<style scoped lang="postcss">
.product-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'image productInfo'
    'image price'
    'image deliveryTime'
    'image quantity'
    'infoBanner infoBanner';

  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}
.product-grid__image {
  grid-area: image;
  @apply mr-sm mt-2xs;
}
.product-grid__productInfo {
  grid-area: productInfo;
  @apply pl-sm;
}
.product-grid__quantity {
  grid-area: quantity;
  @apply my-sm pl-sm;
}
.product-grid__deliveryTime {
  grid-area: deliveryTime;
  @apply pl-sm;
}

.product-grid__infoBanner {
  grid-area: infoBanner;
  @apply bg-status-warn-lighter rounded-alt-md my-sm;
}
.product-grid__price {
  grid-area: price;
  @apply items-start my-sm pl-sm  justify-center;
}

.separator-overwrite :deep(.separator) {
  @apply !pb-md !px-0;
}

@media (min-width: 960px) {
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'image productInfo'
      'image price'
      'image deliveryTime'
      'image quantity'
      'infoBanner infoBanner';

    grid-template-columns: 150px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .product-grid__image {
    grid-area: image;
    @apply mr-sm mt-2xs;
  }
  .product-grid__productInfo {
    grid-area: productInfo;
  }
  .product-grid__quantity {
    grid-area: quantity;
    @apply my-sm;
  }
  .product-grid__deliveryTime {
    grid-area: deliveryTime;
  }

  .product-grid__infoBanner {
    grid-area: infoBanner;
    @apply bg-status-warn-lighter rounded-alt-md my-sm;
  }
  .product-grid__price {
    grid-area: price;
    @apply items-start my-sm  justify-center;
  }
}
</style>
